const SET_POLYGON_DATA = 'SET_POLYGON_DATA';
const SET_BOTTOM_DRAWER_DATA = 'SET_BOTTOM_DRAWER_DATA';

const TOGGLE_LEFT_DRAWER = 'TOGGLE_LEFT_DRAWER';
const TOGGLE_RIGHT_DRAWER = 'TOGGLE_RIGHT_DRAWER';
const TOGGLE_BOTTOM_DRAWER = 'TOGGLE_BOTTOM_DRAWER';

const LEFT_PANEL_VENDOR_SELECT = 'LEFT_PANEL_VENDOR_SELECT';
const RIGHT_PANEL_VENDOR_SELECT = 'RIGHT_PANEL_VENDOR_SELECT';
const MAP_VENDOR_SELECT = 'MAP_VENDOR_SELECT'

const FIND_MY_LOCATION_SELECT = 'FIND_MY_LOCATION_SELECT';
const FIND_MY_LOCATION_ERROR = 'FIND_MY_LOCATION_ERROR';
const FIND_MY_LOCATION_SUCCESS = 'FIND_MY_LOCATION_SUCCESS';
const FIND_MY_LOCATION_OUT_OF_BOUNDS = 'FIND_MY_LOCATION_OUT_OF_BOUNDS';

const SET_MAP = 'SET_MAP';

const SET_TAB_VALUE = 'SET_TAB_VALUE';

const LAYER_FREE_EVENTS = "free-events";
const LAYER_FREE_EVENTS_LABEL = "free-events-label";
const LAYER_FREE_EVENTS_SELECTED = "free-events-selected";
const LAYER_FREE_EVENTS_POLYGON = "free-events-polygon";
const LAYER_NONPROFIT = "sponsors-nonprofit";
const LAYER_NONPROFIT_LABEL = "sponsors-nonprofit-label";
const LAYER_NONPROFIT_SELECTED = "sponsors-nonprofit-selected";
const LAYER_STAGE = "stage";
const LAYER_BEER_GARDEN = "beer-garden-lounge";
const LAYER_BEER_GARDEN_LOUNGE_OUTLINE = "beer-garden-lounge-outline";
const LAYER_BEER_GARDEN_LOUNGE_LABEL = "beer-garden-lounge-label";
const LAYER_BAR_RETAIL_SERVICE = "bars-retail-service";
const LAYER_BAR_RETAIL_SERVICE_SELECTED = "bars-retail-service-selected";
const LAYER_BAR_RETAIL_SERVICE_LABEL = "bars-retail-service-label";

const drawerWidth = 280;

export {
  SET_POLYGON_DATA,
  SET_BOTTOM_DRAWER_DATA,
  TOGGLE_LEFT_DRAWER,
  TOGGLE_RIGHT_DRAWER,
  TOGGLE_BOTTOM_DRAWER,
  LEFT_PANEL_VENDOR_SELECT,
  RIGHT_PANEL_VENDOR_SELECT,
  MAP_VENDOR_SELECT,
  FIND_MY_LOCATION_SELECT,
  FIND_MY_LOCATION_ERROR,
  FIND_MY_LOCATION_SUCCESS,
  FIND_MY_LOCATION_OUT_OF_BOUNDS,
  SET_TAB_VALUE,
  SET_MAP,
  drawerWidth,
  LAYER_BAR_RETAIL_SERVICE,
  LAYER_BAR_RETAIL_SERVICE_LABEL,
  LAYER_BAR_RETAIL_SERVICE_SELECTED,
  LAYER_BEER_GARDEN,
  LAYER_BEER_GARDEN_LOUNGE_LABEL,
  LAYER_BEER_GARDEN_LOUNGE_OUTLINE,
  LAYER_FREE_EVENTS,
  LAYER_FREE_EVENTS_LABEL,
  LAYER_FREE_EVENTS_SELECTED,
  LAYER_FREE_EVENTS_POLYGON,
  LAYER_NONPROFIT,
  LAYER_NONPROFIT_LABEL,
  LAYER_NONPROFIT_SELECTED,
  LAYER_STAGE
}
